.react-calendar-input {
    width: 100%;
    text-align: center;
}

.react-calendar-input .react-daterange-picker__wrapper {
    border-radius: 5px;
    height: 34px;
    border: 1px solid #dfdfdf;
    font-family: inherit;
    background-color: #fff;
    height: 37px;
}

.react-calendar-input label, input, span {
    color: hsl(0,0%,50%);
}

.react-calendar-component {
    width: 100%;
    text-align: center;
    border: 1px solid #ced4da;
    z-index: 9999;
    position: relative;
}

.react-calendar {
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    width: 100%;
}

.react-daterange-picker__calendar {
    width: 100%;
}

.react-calendar__navigation__arrow:hover{
    background-color: #DBDBE6 !important;
    border-radius: 0px 0px 5px 5px !important;
}

.react-calendar-component abbr {
    cursor: auto;
}

.react-calendar-component abbr {
    cursor: auto;
}

.react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none !important;
    cursor: auto !important;
}

.react-daterange-picker__inputGroup {
    text-align: left;
    padding-left: 16px;
    font-size: 14px;
    flex-grow: 0;
    padding-right: 5px;
}

.react-daterange-picker__wrapper > .react-daterange-picker__inputGroup ~ .react-daterange-picker__inputGroup {
    text-align: left;
    padding-left: 5px;
    font-size: 14px;
    flex-grow: 0;
}

.react-daterange-picker__inputGroup__input {
    cursor: pointer;
}

.react-daterange-picker__inputGroup__input {
    color: #444 !important;
}

.react-daterange-picker__inputGroup__leadingZero{
    color: #444 !important;
    font-size: 14px;
    padding-top: 1px !important;
}

.react-daterange-picker__range-divider {
    color: #444 !important;
    font-size: 14px;
}

.react-calendar-component .react-calendar__month-view__days__day abbr {
    cursor: pointer !important;
    text-decoration: none !important;
}

.react-calendar-component .react-calendar__month-view__days__day {
    border-radius: 5px !important;
}

.react-calendar-component .react-calendar__month-view__days__day:hover {
    background-color: #DBDBE6 !important;
}

.react-calendar__tile--hasActive {
    background: #1D1B84 !important;
    color: #fff;
    border-radius: 5px !important;
}

.react-calendar__tile--now {
    background-color: #DBDBE6 !important;
    color: #000;
}

.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
    background: #1d1b84ca !important;
    color: #fff;
}

.react-calendar__tile--active {
    background: #1D1B84 !important;
    color: #fff;
}

.react-daterange-picker__button {
    background: #F2F2F2 !important;
    height: 37px;
    width: 37px;
    float: right;
    position: absolute;
    right: 0;
    border: 1px solid #e5e5e5;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
